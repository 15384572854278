import * as React from "react"
import LayoutPages from '../components/Layout/layoutPages'
import SEO from "../components/seo"
import PuntaMar from "../components/PuntaMar";

const PuntaMarEsPage = (props) => {
  return (
    <LayoutPages>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <PuntaMar {...props}/>

    </LayoutPages>
  )
}

export default PuntaMarEsPage
